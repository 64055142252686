import React, { useEffect, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import ReactGA from 'react-ga4';
import { SessionProvider } from 'next-auth/react';

import FormPopup from '@components/FormPopup';
import { GlobalContext } from '@components/Context/GlobalContext';

import useSpinner from '@hooks/useSpinner';
import useUXCGGlobals from '@hooks/useUXCGGlobals';
import useUXCoreGlobals from '@hooks/useUXCoreGlobals';
import useMobile from '@hooks/useMobile';

import { getMyInfo, getSettings } from '@api/strapi';
import { getStrapiBiases } from '@api/biases';
import { getStrapiQuestions } from '@api/questions';

import { mergeQuestionsLocalization } from '@lib/helpers';

import '../styles/globals.scss';

import dynamic from 'next/dynamic';

const UXCoreFeedbackModal = dynamic(
  () => import('@components/UXCoreFeedbackModal'),
  {
    ssr: false,
  },
);
const UXCorePleaseShareModal = dynamic(
  () => import('@components/UXCorePleaseShareModal'),
  {
    ssr: false,
  },
);
const ChristmasPopup = dynamic(() => import('@components/ChristmasPopup'), {
  ssr: false,
});

const defaultSettings = {
  feedback: false,
  helpToHelp: false,
  pleaseShare: false,
  feedbackSeconds: 0,
  helpToHelpSeconds: 0,
  pleaseShareSeconds: 0,
};

type TApp = {
  Component: any;
  pageProps: any;
};

function App({ Component, pageProps: { session, ...pageProps } }: TApp) {
  const [openPopup, setOpenPopup] = useState('');
  const [settings, setSettings] = useState(defaultSettings);
  const [accountData, setAccountData] = useState(null);
  const [uxCoreData, setUxCoreData] = useState(null);
  const [uxcgData, setUxcgData] = useState(null);
  const isIndexingOn = process.env.NEXT_PUBLIC_INDEXING === 'on';
  const isProduction = process.env.NEXT_PUBLIC_ENV === 'prod';
  const { initUseUXCoreGlobals } = useUXCoreGlobals()[0];
  const { initUseUXCGGlobals } = useUXCGGlobals()[0];
  const { initUseMobile } = useMobile()[0];
  const { events } = useRouter();
  const uxCoreRoute = useRouter();
  const { setIsVisible } = useSpinner()[0];
  const loadingTimer = useRef(null);

  const {
    feedbackSeconds,
    helpToHelpSeconds,
    pleaseShareSeconds,
    feedback: feedbackEnabled,
    helpToHelp: helpToHelpEnabled,
    pleaseShare: pleaseShareEnabled,
  } = settings;
  const uxCorePaths = ['/uxcore', '/uxcg', '/uxcg', '/uxcp'];
  const uxCoreMatchingLinks = uxCorePaths.some(path =>
    uxCoreRoute.pathname.includes(path),
  );

  useEffect(() => {
    const getData = async () => {
      const data = await getMyInfo();

      if (data) {
        setAccountData(data);
      }
    };

    getData();
  }, []);

  // Sharable uxcore data
  useEffect(() => {
    const getBiases = async () => {
      const biases = await getStrapiBiases();
      setUxCoreData(biases);
    };
    setUxCoreData(getBiases);
  }, []);

  // Sharable uxcg data - available for only uxcore links
  useEffect(() => {
    const getQuestions = async () => {
      const questions = await getStrapiQuestions();
      const sortedQuestions = mergeQuestionsLocalization(
        questions.en,
        questions.ru,
      ).sort((a, b) => a.number - b.number);
      setUxcgData(sortedQuestions);
    };
    getQuestions();
  }, []);

  useEffect(() => {
    events.on('routeChangeStart', () => {
      clearTimeout(loadingTimer.current);
      loadingTimer.current = setTimeout(() => {
        setIsVisible(true);
      }, 500);
    });

    events.on('routeChangeComplete', url => {
      if (isIndexingOn && isProduction) {
        ReactGA.set({ page: url });
        ReactGA.send(url);
      }

      clearTimeout(loadingTimer.current);
      setIsVisible(false);
    });

    events.on('routeChangeError', () => {
      clearTimeout(loadingTimer.current);
      setIsVisible(false);
    });
  }, []);

  useEffect(() => {
    initUseUXCoreGlobals();
    initUseUXCGGlobals();
    initUseMobile();

    if (isIndexingOn && isProduction) {
      ReactGA.initialize('G-LR3H9HGZLC');
      setTimeout(() => {
        ReactGA.set({ page: window.location.pathname });
        ReactGA.send(window.location.pathname);
      }, 0);
    }
  }, []);

  useEffect(() => {
    const timeout = setTimeout(() => {
      feedbackEnabled && setOpenPopup('feedback');
    }, feedbackSeconds * 1000);

    return () => {
      clearTimeout(timeout);
    };
  }, [feedbackEnabled, feedbackSeconds]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      pleaseShareEnabled && setOpenPopup('pleaseShare');
    }, pleaseShareSeconds * 1000);

    return () => {
      clearTimeout(timeout);
    };
  }, [pleaseShareEnabled, pleaseShareSeconds]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      helpToHelpEnabled && setOpenPopup('helpToHelp');
    }, helpToHelpSeconds * 1000);

    return () => {
      clearTimeout(timeout);
    };
  }, [helpToHelpEnabled, helpToHelpSeconds]);

  useEffect(() => {
    getSettings().then(settings => {
      setSettings(settings);
    });
  }, []);

  useEffect(() => {
    const savedAccessToken = localStorage.getItem('accessToken');

    const getCookie = (name: string) => {
      let matches = document.cookie.match(
        new RegExp(
          '(?:^|; )' +
          name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') +
          '=([^;]*)',
        ),
      );
      return matches ? decodeURIComponent(matches[1]) : undefined;
    };

    const accessToken = getCookie('accessToken');
    if (savedAccessToken) {
      if (!accessToken) {
        document.cookie = `accessToken=${encodeURIComponent(
          savedAccessToken,
        )}; path=/; Secure; SameSite=Strict;`;
      }
    } else {
      document.cookie = `accessToken=; path=/; Secure; SameSite=Strict;`;
    }
  }, []);

  return (
    <SessionProvider session={session}>
      <GlobalContext.Provider
        value={{
          accountData,
          setAccountData,
          uxCoreData,
          setUxCoreData,
          setUxcgData,
          uxcgData,
        }}
      >
        <Component {...pageProps} />
        {uxCoreMatchingLinks && feedbackEnabled && (
          <UXCoreFeedbackModal open={openPopup === 'feedback'} />
        )}
        {uxCoreMatchingLinks && pleaseShareEnabled && (
          <UXCorePleaseShareModal open={openPopup === 'pleaseShare'} />
        )}
        {uxCoreMatchingLinks && helpToHelpEnabled && (
          <ChristmasPopup open={openPopup === 'helpToHelp'} />
        )}
        <FormPopup />
      </GlobalContext.Provider>
    </SessionProvider>
  );
}

export default App;
