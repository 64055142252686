import { FC, useCallback } from 'react';
import cn from 'classnames';

import Loader from '@icons/Loader';

import styles from './Button.module.scss';

type TButton = {
  type?: 'default' | 'primary' | 'secondary' | 'orange' | 'orange_outline';
  label: string;
  disabled?: boolean;
  onClick: () => void;
  icon?: any;
  iconClassName?: string;
  className?: string;
  loading?: boolean;
  rightIconClassName?: string
  rightIcon?: any
};

const Button: FC<TButton> = ({
  type,
  label,
  disabled,
  onClick,
  icon,
  iconClassName,
  className,
  loading,
  rightIcon,
  rightIconClassName

}) => {
  /**
   * className: example of usage - {styles["startBtn"]
   */

  const handleClick = useCallback(() => {
    
    if (!disabled) onClick();
  }, [onClick, disabled]);

  return (
    <button
      className={cn(styles.Button, className, {
        [styles.Primary]: type === 'primary',
        [styles.Disabled]: disabled,
        [styles.Secondary]: type === 'secondary',
        [styles.Orange]: type === 'orange',
        [styles.OrangeOutline]: type === 'orange_outline',
      })}
      onClick={handleClick}
    >
      {loading && <Loader className={styles.Loader} />}
      {!!icon && (
        <span className={cn(iconClassName, styles.iconWrapper)}>{icon}</span>
      )}
      {loading && 'Loading...'}
      {!loading && label}
      {!!rightIcon && (
        <span className={cn(rightIconClassName, styles.iconWrapper)}>{rightIcon}</span>
      )}
    </button>
  );
};

export default Button;
